import React, { useCallback, useState, useMemo } from 'react'
import { graphql, Link } from 'gatsby'
// import useGatsbyData from '../../hooks/use-gatsby-data'
import Img from "gatsby-image"
import styled from 'styled-components'
import { useMediaQueryContext } from '../../context/media'
import { motion } from 'framer-motion'
import tw from 'tailwind.macro'
import viewportMotion from '../viewport-motion'
import { emptyVariants, motionTransition } from '../../util'
import SquaresSVG from '../SquaresBackground'
import SkewBackground from 'components/SkewBackground'
import { SquaresBackgroundSvg } from '../../templates/home'
import TextTransition from '../text-transition'
import ProjectsSwiper from '../projects-swiper'
import Container from '../container'

const CasesContainer = styled(Container)`
  max-width: 1200px !important;
  ${tw`mx-auto md:mx-10 md:mb-8 lg:mx-auto`}

  a {
    &:hover, &:focus {
      color: inherit;
    }
  }
`

const desktopCaseDetailVariants = {
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      delayChildren: .25,
    }
  },

  hidden: {
    opacity: 0,
  },
}

const desktopCaseDetailInnerVariants = {
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
  },

  hidden: {
    y: 30,
    opacity: 0,
  }
}

const desktopCaseDetailDecorVariants = {
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      ...motionTransition,
      mass: 1,
      // dampling: 50,
      stiffness: 20,
      delay: .6,
    }
  },

  hidden: {
    opacity: 0,
    transition: motionTransition,
  }
}

const DesktopCaseItem = ({ id, title, description, mainImage, route, ...props }) => {
  const [hover, setHover] = useState(false)
  const mouseEnter = useCallback(() => setHover(true), [])
  const mouseLeave = useCallback(() => setHover(false), [])
  const transition = useMemo(() => ({
    ...motionTransition,
    mass: 1,
    sniffness: 20,
  }), [])

  return (
    <motion.li
      key={id}
      variants={listItemDesktopVariants}
      initial="hidden"
      transition={transition}
      {...props}
    >
      <div className="relative rounded overflow-hidden" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <Link to={route} className="block hover:text-inherit focus:text-inherit">
          <Img
            fluid={mainImage.sharp.fluid}
            className="rounded stretched-link"
            title={title}
            style={{ display: `block` }}
          />

          <motion.div
            variants={desktopCaseDetailVariants}
            animate={hover ? `visible` : `hidden`}
            initial="hidden"
            transition={motionTransition}
            className="case-details bg-white absolute inset-0 flex flex-row justify-between items-center p-10"
          >
            <motion.div
              variants={desktopCaseDetailInnerVariants}
              transition={motionTransition}
            >
              <h3 className="text-gray-600 font-bold text-3xl mb-0">{title}</h3>
              <div className="font-semibold">{description}</div>
            </motion.div>

            <motion.div variants={desktopCaseDetailDecorVariants}  className="flex-shrink-0">
              <SquaresSVG rows={4} padding={10} width={110} className="block" />
            </motion.div>
          </motion.div>
        </Link>
      </div>
    </motion.li>
  )
}

export const CasesDesktop = ({ items, itemClassName = `w-1/2 px-4 my-4`, className, ...props }) => (
  <viewportMotion.ul
    className={`flex flex-row flex-wrap -mx-4 justify-center ${className}`}
    variants={listDesktopVariants}
    initial="hidden"
    transition={{ motionTransition, mass: 0.75 }}
    {...props}
  >
    {items.map(item => <DesktopCaseItem key={item.id} {...item} className={itemClassName} /> )}
  </viewportMotion.ul>
)

const ProjectsBase = ({ className, data, ...props }) => {
  // const data = useGatsbyData()
  
  const breakpoints = useMediaQueryContext()

  return (
    (data.length &&
      (!breakpoints.lg ? (
        <>
          <ProjectsSwiper className={className} items={data} />
        </>
      ) : (
        <CasesContainer className="container">
          <CasesDesktop items={data} />
        </CasesContainer>
      ))) ||
    null
  )
}

const listDesktopVariants = {
  visible: {
    transition: {
      staggerChildren: .15,
    }
  },
  hidden: {}
}

const visibleVariant = { opacity: 1, x: 0, y: 0 }

const listItemDesktopVariants =  {
  visible: visibleVariant,
  hidden: {
    opacity: 0,
    y: 50
  },
}

const Projects = styled(ProjectsBase)`

`

const ProjectsSection = ({ id, data, ...props }) => (
  <section id={id} data-loadable-fallback={`section-${id}`}>
    <SkewBackground
      className="flex flex-col justify-center py-16"
      bgColor="#222"
    >
      <div className="pt-12 lg:pt-12">
        <SquaresBackgroundSvg
          fillColor="black"
          className="mb-8"
        >
          <h2 className="section-title">
            <viewportMotion.span
              variants={emptyVariants}
              className="inline-block bg-gray-600 px-4"
            >
              <TextTransition text="Case Studies" />
            </viewportMotion.span>
          </h2>
        </SquaresBackgroundSvg>

        <Projects data={data} />
      </div>
    </SkewBackground>
  </section>
)

const ProjectsSectionWithData = ({ data: { allProject: { nodes } }, ...props }) => {
  // const { allProject: { nodes } } = useGatsbyData()

  return (
    <ProjectsSection {...props} data={nodes} />
  )
}

export default ProjectsSectionWithData

export const projectFragment = graphql`
  fragment ProjectFragment on Project {
    id
    projectURL
    title
    route
    description

    mainImage {
      sharp: childImageSharp {
        fluid(quality: 90, maxWidth: 720) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export const projectsFragment = graphql`
  fragment ProjectsFragment on Query {
    allProject(sort: {fields: [projectYear], order: [DESC]}) {
      nodes {
        ...ProjectFragment
      }
    }
  } 
`
