import React, { useMemo } from 'react'
import 'react-id-swiper/lib/styles/css/swiper.css'
import IdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper, Pagination } from 'swiper/dist/js/swiper.esm.js'
import styled from 'styled-components'
import { theme } from '../twconfig'
import Img from 'gatsby-image'
import { Link } from 'gatsby'


const SwiperSlide = styled.div`
  &.swiper-slide {
    width: 320px;
    
    @media (min-width: ${theme.screens.md}) {
      width: 480px;
    }

    .gatsby-image-wrapper {
      transition: all .3s ease-in-out;
    }
  
    .project-excerpt {
      opacity: 0;
      transition: all .3s ease-in-out;
    }
  
    &:not(.swiper-slide-active) {
      .gatsby-image-wrapper {
        transform: scale(.9);
      }
    }
  }

  &.swiper-slide-active {
    .project-excerpt {
      opacity: 1;
    }
  }
`

const swiperConfig = {
  Swiper,
  modules: [Pagination],
  slidesPerView: `auto`,
  spaceBetween: 0,
  centeredSlides: true,
  // loop: true,
  // loopedSlides: 1,
}

const ProjectsSwiper = ({ className, items, ...props }) => {
  const config = useMemo(() => ({
    ...swiperConfig,
    containerClass: `swiper-container ${className}`,
  }), [className])

  return (
    <IdSwiper {...config}>
      {items.map(
        ({ id, title, description, mainImage, route }) =>
        mainImage ? (
          <SwiperSlide key={id}>
            <div className="text-center text-base relative">
              <Img
                fluid={mainImage.sharp.fluid}
                className="rounded"
                title={title}
              />
              <Link to={route}>
                <h3 className="my-4 stretched-link">{title}</h3>
              </Link>
              <div className="project-excerpt" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </SwiperSlide>
        ) : null
      )}
    </IdSwiper>
  )
}

export default ProjectsSwiper
